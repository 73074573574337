<template>
	<div ref="scrollComponent">
		<slot />
		<div v-if="isLoading" class="pagination-loader">
			<loader-component />
		</div>
		<div v-if="isNetworkError" class="container">
			<button-component color="transparent" @click="retryLoad" :loading="isLoading">
				{{ $t('reload') }}
			</button-component>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useModalStore } from '@/stores/modal'
import ScrollKeeper from '@/helpers/scrollKeeper'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { findParentBySelector } from '@/helpers/elementSelector'
import LoaderComponent from '@/components/LoaderComponent.vue'

const scrollKeeper = new ScrollKeeper()

export default {
	name: 'InfiniteScroll',
	components: {
		ButtonComponent,
		LoaderComponent
	},
	props: {
		loadFunction: {
			type: Function,
			required: true
		},
		elementsToTheEnd: {
			type: Number,
			default: 5
		},
		saveScroll: {
			type: Boolean,
			default: false
		},
		showPWA: {
			type: Boolean,
			default: false
		},
		targetElementSelector: {
			type: String,
			default: null,
			required: false
		}
	},
	data() {
		return {
			elementHeight: null,
			isNetworkError: false,
			isLoading: false,
			itFirstPage: true,
			targetElement: null
		}
	},
	computed: {
		routeName() {
			let routeName = this.$route.name
			const userId = this.$route.params.id
			const nickName = this.$route.params.nickname
			if (nickName) {
				routeName = `${routeName}/${nickName}`
			} else if (userId) {
				routeName = `${routeName}/${userId}`
			}
			return routeName
		}
	},
	methods: {
		...mapActions(useModalStore, ['openPWADialog']),
		getElementHeight() {
			const { scrollComponent } = this.$refs
			const firstChild = scrollComponent.children[0]
			if (firstChild) {
				this.elementHeight = firstChild.clientHeight
			}
		},
		handleScroll() {
			if (!this.elementHeight) this.getElementHeight()

			const { scroll, pixelsLeft } = this.targetElement ? this.elementPixelsLeft() : this.windowPixelsLeft()

			if (this.saveScroll) {
				scrollKeeper.saveScroll(this.routeName, scroll)
			}

			if (pixelsLeft < this.elementHeight * this.elementsToTheEnd) {
				this.loadMoreData()
			}
		},
		windowPixelsLeft() {
			const scrollHeight = Math.max(
				document.body.scrollHeight,
				document.documentElement.scrollHeight,
				document.body.offsetHeight,
				document.documentElement.offsetHeight,
				document.body.clientHeight,
				document.documentElement.clientHeight
			)

			const scroll = window.scrollY
			const pixelsLeft = scrollHeight - window.innerHeight - scroll
			return { scroll, pixelsLeft }
		},
		elementPixelsLeft() {
			const { scrollHeight, clientHeight, scrollTop } = this.targetElement
			const pixelsLeft = scrollHeight - clientHeight - scrollTop
			return { scroll: scrollTop, pixelsLeft }
		},
		async loadMoreData() {
			if (!this.itFirstPage && this.showPWA) {
				this.openPWADialog()
			}
			if (!this.isLoading && !this.isNetworkError) {
				this.isLoading = true
				this.itFirstPage = false
				try {
					await this.loadFunction()
					this.isNetworkError = false
				} catch (error) {
					if (error.code === 'ERR_NETWORK') {
						this.isNetworkError = true
					}
				} finally {
					this.isLoading = false
				}
			}
		},
		retryLoad() {
			this.isNetworkError = false
			this.loadMoreData()
		}
	},
	mounted() {
		if (this.targetElementSelector) {
			this.targetElement = findParentBySelector(this.$el, this.targetElementSelector)
		}

		if (this.saveScroll && !scrollKeeper.getSavedScroll(this.routeName)) {
			scrollKeeper.saveScroll(this.routeName, 0)
		}

		const target = this.targetElement || window
		target.addEventListener('scroll', this.handleScroll)
	},
	beforeUnmount() {
		const target = this.targetElement || window
		target.removeEventListener('scroll', this.handleScroll)
	}
}
</script>

<style>
.pagination-loader {
	padding: 20px;
	display: flex;
	justify-content: center;
	font-size: 48px;
	color: $color-blue-light;
	width: 100%;
}
</style>
