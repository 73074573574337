<template>
	<dialog-component class="consumers-lists-dialog" @close="$emit('close')">
		<infinite-scroll
			:load-function="getMoreConsumersLists"
			target-element-selector=".dialog-body"
			class="consumers-lists"
		>
			<label class="label" v-for="list in consumersLists" :key="JSON.stringify(list)">
				<consumer-lists-item :list="list">
					<custom-checkbox @input="selectList(list)" :checked="listsToSave.find((l) => l.id === list.id)" />
				</consumer-lists-item>
			</label>
		</infinite-scroll>
		<button class="create-list-button" type="button" @click="showListNameDialog = true">
			<span class="create-list-button-circles">
				<span />
				<span>
					<svg-icon name="plus-bold" />
				</span>
			</span>
			{{ $t('consumersList.newOne') }}
		</button>
		<template #dialog-footer>
			<button-component @click="addToList" :loading="isLoading">
				{{ $t('complete') }}
			</button-component>
		</template>
		<dialog-component v-if="showListNameDialog" @close="closeListNameDialog">
			<template #header-title>
				{{ $t('consumersList.name') }}
			</template>
			<form-component @submit="onCreateNewList">
				<input-component
					name="consumersListName"
					v-model="newListName"
					:placeholder="$t('consumersList.enterName')"
					autocomplete="off"
					:rules="consumersListSchema"
				/>
				<div class="flex gap20 mt-20">
					<button-component color="primary-outline" @click="closeListNameDialog">
						{{ $t('cancel') }}
					</button-component>
					<button-component type="submit" :loading="isLoading">
						{{ $t('complete') }}
					</button-component>
				</div>
			</form-component>
		</dialog-component>
	</dialog-component>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useModalStore } from '@/stores/modal'
import { useCommonStore } from '@/stores/common'
import { useConsumersListStore } from '@/stores/girl/consumersLists/index'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ConsumerListsItem from '@/components/ConsumerListsItem.vue'
import InputComponent from '@/components/forms/InputComponent.vue'
import FormComponent from '@/components/forms/FormComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import InfiniteScroll from '@/components/InfiniteScroll.vue'
import CustomCheckbox from '@/components/CustomCheckbox.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { consumersListSchema } from '@/helpers/validationSchemas'

export default {
	name: 'ConsumersListsDialog',
	components: {
		FormComponent,
		InputComponent,
		InfiniteScroll,
		ButtonComponent,
		CustomCheckbox,
		DialogComponent,
		ConsumerListsItem,
		SvgIcon
	},
	setup() {
		return { consumersListSchema }
	},
	data() {
		return {
			showListNameDialog: false,
			newListName: ''
		}
	},
	computed: {
		...mapState(useCommonStore, {
			isLoading: 'isLoading'
		}),
		...mapState(useConsumersListStore, {
			consumersLists: 'consumersLists',
			listsToSave: 'listsToSave'
		})
	},
	methods: {
		...mapActions(useModalStore, {
			closeConsumersListsDialog: 'closeConsumersListsDialog'
		}),
		...mapActions(useConsumersListStore, {
			getMoreConsumersLists: 'getMoreConsumersLists',
			addNewConsumersList: 'addNewConsumersList',
			clearConsumerToAdd: 'clearConsumerToAdd',
			initConsumersLists: 'initConsumersLists',
			addConsumerToList: 'addConsumerToList',
			selectLastList: 'selectLastList',
			selectList: 'selectList'
		}),
		async addToList() {
			await this.addConsumerToList()
			this.closeConsumersListsDialog()
		},
		closeListNameDialog() {
			this.showListNameDialog = false
			this.newListName = ''
		},
		async onCreateNewList() {
			await this.addNewConsumersList({ name: this.newListName, consumers: [] })
			this.selectLastList()
			this.closeListNameDialog()
		}
	},
	async mounted() {
		await this.initConsumersLists()
	},
	beforeUnmount() {
		this.clearConsumerToAdd()
	}
}
</script>

<style scoped lang="scss">
.consumers-lists {
	gap: 15px;
	display: flex;
	flex-direction: column;
}

.dialog-backdrop.consumers-lists-dialog:deep(> .dialog) {
	height: 60%;
	max-height: 60%;
	display: flex;
	flex-direction: column;

	.dialog-header {
		padding: 10px 20px 20px;
	}

	.dialog-body {
		height: 100%;
		overflow: scroll;
		gap: 15px;
		display: flex;
		flex-direction: column;
	}
}

.label {
	padding: 0;
	cursor: pointer;
}

.create-list-button {
	display: flex;
	background: transparent;
	border: none;
	cursor: pointer;
	align-items: center;
	gap: 14px;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	color: $color-white;

	&-circles {
		display: flex;

		span {
			display: block;
			width: 40px;
			height: 40px;
			border: 2px solid $color-gray;
			border-radius: 50%;

			&:first-child {
				border-color: rgba($color-gray, 50%);
				overflow: hidden;
			}

			&:last-child {
				margin-left: -20px;
				background: $color-black;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				color: $color-gray;
			}
		}
	}
}
</style>
