<template>
	<div class="consumer-lists-item">
		<div class="consumer-lists-item-content" @click="$emit('click')">
			<div class="consumer-lists-item-avatars">
				<span v-for="i in 2 - firstTwoUsers.length" :key="`placeholder${i}`" class="consumer-lists-item-avatar" />
				<img
					class="consumer-lists-item-avatar"
					v-for="user in firstTwoUsers"
					:key="JSON.stringify(user)"
					:src="user.avatar"
					alt=""
				/>
			</div>
			<div class="consumer-lists-item-info">
				<span class="consumer-lists-item-name">{{ list.name }}</span>
				<span class="consumer-lists-item-count">{{ consumersCount }}</span>
			</div>
		</div>
		<slot>
			<button type="button" class="consumer-lists-item-button" @click.stop="showOptions">
				<svg-icon :name="isCustomList ? 'ellipsis-vertical' : 'info'" size="20px" />
			</button>
		</slot>
	</div>
</template>

<script>
// import pluralize from '@/helpers/plural'
import SvgIcon from '@/components/SvgIcon.vue'

export default {
	name: 'ConsumerListsItem',
	components: {
		SvgIcon
	},
	props: {
		list: { type: Object, required: true }
	},
	computed: {
		consumersCount() {
			return `${this.list.consumers_count} ${this.$t('human', this.list.consumers_count)}`
		},
		firstTwoUsers() {
			return this.list.consumers.slice(0, 2)
		},
		isCustomList() {
			return this.list.type === 'custom'
		}
	},
	methods: {
		showOptions() {
			if (this.isCustomList) {
				this.$emit('show-options', this.list)
				return
			}
			this.$emit('show-info', this.list)
		}
	}
}
</script>

<style scoped lang="scss">
.consumer-lists-item {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;

	&-content {
		display: flex;
		width: 100%;
		gap: 10px;
		align-items: center;
		overflow: hidden;
	}

	&-avatars {
		display: flex;
	}

	&-avatar {
		width: 46px;
		height: 46px;
		object-fit: cover;
		border-radius: 50%;
		border: 3px solid $color-black;

		&:first-child {
			background: $color-black-secondary;
		}

		&:last-child {
			background: $color-black-additional-fourth;
			margin-left: -28px;
		}
	}

	&-info {
		display: flex;
		flex-direction: column;
		font-size: 14px;
		line-height: 20px;
		width: 100%;
		overflow: hidden;
	}

	&-name {
		font-weight: 700;
		color: $color-white;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 20px;
	}

	&-count {
		font-weight: 400;
		color: $color-gray;
		margin-top: -2px;
	}

	&-button {
		width: 50px;
		height: 20px;
		background: transparent;
		border: none;
		color: $color-gray;
		cursor: pointer;
		text-align: right;
	}
}
</style>
