<template>
	<dialog-component class="unsub-and-unfollow-dialog" @close="closeDialog">
		<template #header-title>Вы фанат</template>
		<p class="text">Вы уверены, что хотите отписаться от {{ profile.nickname }} и перестать следить за ней?</p>
		<button-component color="transparent" @click="onUnsubscribe">
			Отключить доступ к постам для фанатов
		</button-component>
		<button-component color="transparent" @click="onUnfollowAndUnsubscribe">Отписаться от модели</button-component>
		<button-component color="white" @click="closeDialog">Отмена</button-component>
	</dialog-component>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import subscriptionsMixin from '@/mixins/subscriptionsMixin'
import { mapActions, mapState } from 'pinia'
import { useModalStore } from '@/stores/modal'
import { useFollowingStore } from '@/stores/consumer/following/index'
import { useGirlStore } from '@/stores/girl'

export default {
	name: 'UnsubscribeAndUnfollowDialog',
	mixins: [subscriptionsMixin],
	components: { DialogComponent, ButtonComponent },
	computed: {
		...mapState(useGirlStore, { profile: 'profile' }),
		...mapState(useFollowingStore, {
			girlId: 'girlId'
		})
	},
	methods: {
		...mapActions(useFollowingStore, {
			stopFollowing: 'stopFollowing'
		}),
		...mapActions(useModalStore, {
			closeUnsubscribeAndUnfollowDialog: 'closeUnsubscribeAndUnfollowDialog'
		}),
		async onUnsubscribe() {
			await this.$_stopPaidSubscription(this.girlId)
			this.closeDialog()
		},
		async onUnfollowAndUnsubscribe() {
			await this.$_stopPaidAndFreeSubscription(this.girlId)
			this.closeDialog()
		},
		closeDialog() {
			this.closeUnsubscribeAndUnfollowDialog()
		}
	}
}
</script>

<style scoped lang="scss">
.unsub-and-unfollow-dialog {
	.text {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		text-align: left;
		color: $color-gray;
		@include media-desktop {
			font-size: 16px;
		}
	}

	&:deep(.dialog-body) {
		display: grid;
		gap: 16px;
	}
}
</style>
