<template>
	<confirmation-dialog
		class="confirm-age-dialog"
		btn-accept-text="Мне нет 18"
		btn-decline-text="Мне есть 18"
		@close="closeConfirmAgeDialog"
		:is-sending="isLoading"
		@accept="onDecline"
		@decline="onAccept"
	>
		Этот контент доступен только тем, кто подтвердил свое совершеннолетие.
	</confirmation-dialog>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue'
import { mapActions, mapState } from 'pinia'
import { useModalStore } from '@/stores/modal'
import { useProfileStore } from '@/stores/profile'

export default {
	name: 'ConfirmAgeDialog',
	components: { ConfirmationDialog },
	computed: {
		...mapState(useProfileStore, {
			isLoading: 'isLoading'
		})
	},
	methods: {
		...mapActions(useProfileStore, {
			confirmAge: 'confirmAge'
		}),
		...mapActions(useModalStore, {
			closeConfirmAgeDialog: 'closeConfirmAgeDialog'
		}),
		async onAccept() {
			await this.confirmAge()
				.then(() => window.location.reload())
				.catch((e) => {
					throw new Error(e)
				})
			this.closeConfirmAgeDialog()
		},
		onDecline() {
			this.closeConfirmAgeDialog()
		}
	}
}
</script>

<style scoped lang="scss">
.confirm-age-dialog {
	&:deep(.dialog-body) {
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
	}
}
</style>
