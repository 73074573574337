<template>
	<div class="form-control">
		<template v-if="!hideInput">
			<label v-if="label" :for="name" class="form-label">{{ label }}</label>
			<div class="form-field-wrap" :class="{ invalid: errors.length }">
				<input :id="name" v-bind="$attrs" v-model="value" class="form-field" @focus="event('focus')" />
				<slot name="append" />
			</div>
		</template>
		<validation-message v-if="errors.length" :messages="errors" />
	</div>
</template>

<script setup>
import { watch } from 'vue'
import { useField } from 'vee-validate'
import ValidationMessage from '@/components/forms/ValidationMessage.vue'

const props = defineProps({
	name: { type: String, required: true },
	label: { type: String, default: '' },
	modelValue: { type: [String, Number, Array], default: '' },
	rules: {
		type: Object,
		default: () => null
	},
	hideInput: {
		type: Boolean,
		default: false
	}
})

const event = defineEmits(['update:modelValue', 'focus', 'error'])
const { value, errors } = useField(() => props.name, props.rules, {
	syncVModel: true
})

watch(errors, (val) => {
	event('error', val)
})
</script>
