import { defineStore } from 'pinia'
import { getMoreData, findPostAndUpdateLikedComment } from '@/stores/helper'
import { useHomeStore } from '@/stores/home'
import { useSearchStore } from '@/stores/search'
import { useConsumerStore } from '@/stores/consumer'
import { useGirlStore } from '@/stores/girl'
import postsApi from '@/http/posts'
import { useGirlSchedule } from '@/stores/girlSchedule'

export const usePostStore = defineStore('post', {
	state: () => ({
		attachments: null,
		author: {
			avatar: '',
			has_in_fans_current_consumer: null,
			id: null,
			nickname: ''
		},
		description: '',
		hashtags: [],
		id: null,
		is_favorite: null,
		mentioned: {},
		audience: null,
		published_text: '',
		total_comments: null,
		total_consumer_likes: null,
		total_likes: null,
		visibility: '',
		girl_liked_any_comment: null,
		publication_status: '',
		comments: {
			data: [],
			links: {},
			meta: {}
		},
		consumersWithLikes: null,
		isLoading: false,
		isReady: false,
		commentsLoaded: false,
		homeStore: useHomeStore(),
		searchStore: useSearchStore(),
		consumerStore: useConsumerStore(),
		girlStore: useGirlStore(),
		girlSchedule: useGirlSchedule(),
		acting_consumer_is_follower: false
	}),
	actions: {
		toggleFollowingState() {
			this.acting_consumer_is_follower = !this.acting_consumer_is_follower
		},
		removeBlurFromPost() {
			this.author.has_in_fans_current_consumer = true
		},
		applyBlurToPost() {
			this.author.has_in_fans_current_consumer = false
		},
		incrementTotalLikes() {
			if (this.isReady) {
				this.total_consumer_likes += 1
				this.total_likes += 1
			}
		},
		async getPost(id) {
			await this.$reset()
			this.isLoading = true
			try {
				const result = await postsApi.getPost(id)
				if (result) {
					Object.assign(this.$state, result)
					this.isReady = true
				}
				// eslint-disable-next-line
			} catch (error) {
				throw error
			} finally {
				this.isLoading = false
			}
		},
		async setPost(post) {
			Object.assign(this.$state, post)
			this.isReady = true
		},
		async fetchPosts(id = undefined, controller = undefined) {
			if (id) return postsApi.getPostsByGirlId(id)
			return postsApi.getPosts(controller)
		},
		async getMorePosts(posts) {
			await getMoreData(posts, postsApi.getMorePosts)
		},
		async getPostComments(id) {
			this.isLoading = true
			const result = await postsApi.getPostComments(id)
			if (result) {
				Object.assign(this.comments, result)
				this.commentsLoaded = true
			}
			this.isLoading = false
		},
		removeCommentsFromState() {
			this.commentsLoaded = false
			this.comments = {
				data: [],
				links: {},
				meta: {}
			}
		},
		async getMorePostComments() {
			await getMoreData(this.comments, postsApi.getMorePostComments)
		},
		async sendComment(id, comment) {
			this.isLoading = true
			try {
				const result = await postsApi.sendComment(id, comment)
				this.comments.data.unshift(result)
				this.updateCount(1)
				// eslint-disable-next-line
			} catch (error) {
				throw error
			} finally {
				this.isLoading = false
			}
		},
		async likeComment(commentId, postId) {
			const result = postsApi.likeComment(commentId)
			if (result) {
				this.girl_liked_any_comment = true
				this.comments.data
					.filter((comment) => comment.id === commentId)
					.map(({ comment }) => {
						comment.liked_time_as_text = this.$i18n.global.t('now')
						return comment
					})
				const posts = [
					this.homeStore.posts,
					this.searchStore.posts,
					this.searchStore.recommendedPosts,
					this.consumerStore.awards,
					this.consumerStore.likedPosts,
					this.girlStore.posts
				]
				findPostAndUpdateLikedComment(posts, postId)
			}
		},
		async deletePost(postId) {
			const result = await postsApi.deletePost(postId)
			if (result) {
				this.homeStore.removePostFromList(postId)
				this.searchStore.removePostFromList(postId)
				this.consumerStore.removePostFromList(postId)
				this.girlStore.removePostFromList(postId)
				this.girlSchedule.removePostFromList(postId)
			}
		},
		updateCount(value) {
			const posts = [
				this.homeStore.posts,
				this.searchStore.posts,
				this.searchStore.recommendedPosts,
				this.consumerStore.awards,
				this.consumerStore.likedPosts,
				this.girlStore.posts
			]
			posts.forEach((val) => {
				const postsCollection = val?.data
				if (postsCollection) {
					const post = postsCollection.find((p) => p.id === this.id)
					if (post) {
						post.total_comments += value
					}
				}
			})
			this.total_comments += value
		},
		async getConsumersWithLikes(postId) {
			try {
				if (this.consumersWithLikes) {
					this.consumersWithLikes.data = []
				}
				this.isLoading = true
				this.consumersWithLikes = await postsApi.consumersWithLikes(postId)
			} finally {
				this.isLoading = false
			}
		},
		async getMoreConsumersWithLikes() {
			await getMoreData(this.consumersWithLikes, postsApi.getMoreConsumersWithLikes)
		}
	}
})
