<template>
	<div class="media-video">
		<video
			playsinline
			class="video placeholder-bg"
			:src="`${video?.file}#t=0.001`"
			v-lazy.poster="videoPreview"
			:width="video?.preview.width"
			:height="video?.preview.height"
			preload="metadata"
			:muted="muted"
			loop="loop"
			:ref="`video${id}`"
			@play="onPlay"
			@playing="onPlaying"
			@waiting="onWaiting"
			@loadstart="onLoadStart"
			@loadedmetadata="onLoadedMetaData($event)"
			@timeupdate="durationChange($event)"
			@error="onError"
		/>
		<video-processing-notice v-if="showProcessedMsg">{{ $t('post.videoProcessing') }}</video-processing-notice>
		<template v-else>
			<div class="post-video-play" v-if="showPlayButton" @click="onRetry" />
			<div class="post-media-meta" v-if="showTimer">
				{{ `0:${progress}` }}
			</div>
			<div v-if="showLoader" class="post-media-meta">
				<loader-component class="video-loader" size="17px" />
			</div>
		</template>
		<div v-if="showCameraIcon" class="post-video-icon">
			<img src="@/assets/images/icons/camera.svg" alt="camera" />
		</div>
		<div class="media-video-volume" :class="{ muted }" @click="toggleVolume" v-if="showIcons">
			<svg-icon v-if="muted" name="sound-off" :size="volumeIconSize" />
			<svg-icon v-else name="sound-on" :size="volumeIconSize" />
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useCommonStore } from '@/stores/common'
import LoaderComponent from '@/components/LoaderComponent.vue'
import VideoProcessingNotice from '@/components/VideoProcessingNotice.vue'
import SvgIcon from '@/components/SvgIcon.vue'

export default {
	name: 'PostMediaVideo',
	components: { VideoProcessingNotice, LoaderComponent, SvgIcon },
	props: {
		video: { type: Object, default: () => {} },
		id: { type: Number, default: 0 },
		isOwner: { type: Boolean, required: false }
	},
	data() {
		return {
			showPlayButton: false,
			showLoader: false,
			showCameraIcon: true,
			muted: true,
			showTimer: false,
			duration: null,
			currentTIme: null,
			firstPlay: true
		}
	},
	computed: {
		...mapState(useCommonStore, ['isPowerSaveMode']),
		progress() {
			const result = Math.ceil(this.duration - this.currentTIme)
			return result < 10 ? `0${result}` : result
		},
		currentVideo() {
			return this.$refs[`video${this.id}`]
		},
		showProcessedMsg() {
			return this.video?.file === null && this.isOwner
		},
		showIcons() {
			return !!this.video?.file
		},
		videoPreview() {
			if (this.video)
				return this.video.preview.blurred_image?.url || this.video.preview.blurred_thumbnail || this.video.preview.url
			return ''
		},
		volumeIconSize() {
			if (window.screen.width > 980) {
				return '24px'
			}
			return '16px'
		}
	},
	methods: {
		addVideoToObserver() {
			const observer = new IntersectionObserver((entries) => {
				entries.forEach(async (entry) => {
					const video = entry.target
					const play = async () => {
						if (entry.isIntersecting) {
							await video
								.play()
								.then()
								.catch(() => {
									this.onError()
								})
						}
					}
					if (entry.isIntersecting) {
						switch (video.readyState) {
							case 0: {
								if (this.isPowerSaveMode) {
									await video.load()
								} else {
									await video.load()
									await play()
								}
								break
							}
							case 3:
							case 4: {
								if (!this.isPowerSaveMode) {
									await play()
								} else if (this.isPowerSaveMode && !this.firstPlay) {
									await play()
								}
								break
							}
							default:
								break
						}
					} else {
						await video.pause()
					}
				})
			})
			observer.observe(this.currentVideo)
		},
		onError(e) {
			if (!e?.target?.currentSrc?.includes('null')) {
				this.showPlayButton = true
			}
			this.showLoader = false
			this.showCameraIcon = true
			this.showTimer = false
		},
		async onRetry(e) {
			e.stopPropagation()
			this.showPlayButton = false
			await this.currentVideo.load()
			this.currentVideo.play()
		},
		onLoadStart() {
			if (this.showIcons && !this.isPowerSaveMode) {
				this.showCameraIcon = false
				this.showLoader = true
			}
		},
		onPlay() {
			if (this.isPowerSaveMode) {
				this.firstPlay = false
				this.showCameraIcon = false
			}
		},
		onPlaying() {
			this.showPlayButton = false
			this.showLoader = false
			this.showCameraIcon = false
			this.showTimer = true
		},
		onWaiting() {
			this.showTimer = false
			this.showLoader = true
		},
		durationChange(e) {
			this.currentTIme = e.target.currentTime
		},
		onLoadedMetaData(e) {
			this.$emit('videoLoaded')
			this.duration = e.target.duration
		},
		toggleVolume(e) {
			e.stopPropagation()
			this.muted = !this.muted
		}
	},
	mounted() {
		if (this.video && this.video?.file !== null) {
			this.addVideoToObserver()
			if (this.isPowerSaveMode) {
				this.showPlayButton = true
				this.showLoader = false
				this.showCameraIcon = true
				this.showTimer = false
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.video-loader {
	width: auto;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.media-video {
	overflow: hidden;
	width: inherit;
	height: inherit;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: center;
}

.media-video video {
	width: 100%;
	object-fit: cover;
	height: auto;
}

.media-video-volume {
	position: absolute;
	z-index: 10;
	right: 16px;
	bottom: 16px;
	width: 24px;
	height: 24px;
	background-color: rgba($color-black, 0.5);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (min-width: $screen-desktop) {
		cursor: pointer;
		width: 34px;
		height: 34px;
		transition: $transition-color;
	}
	&:hover {
		color: $color-white-hover;
	}
}
</style>
