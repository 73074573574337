import Resumable from 'resumablejs'
import { defineStore } from 'pinia'
import { useCreatePostStore } from '@/stores/createPost'
import { useModalStore } from '@/stores/modal'
import createPostApi from '@/http/girl/createPost'

export const useVideoUploadStore = defineStore('videoUpload', {
	state: () => ({
		uploader: null,
		files: [],
		progress: 0,
		isVideoUploading: false,
		isVideoUploaded: false
	}),
	actions: {
		initUploader() {
			this.uploader = new Resumable({
				target: `${this.$config.apiUrl}girls/posts-videos`,
				maxChunkRetries: 1,
				maxFileSize: 2147483648,
				maxFileSizeErrorCallback: () => {
					useCreatePostStore().showValidationError(
						this.$i18n.global.t('error.videoBig', { size: 2 }),
						this.$i18n.global.t('error.changeSize')
					)
				},
				fileTypeErrorCallback: () => {
					useCreatePostStore().showValidationError(
						this.$i18n.global.t('error.format', { format: 'MP4, MOV' }),
						this.$i18n.global.t('error.changeFormat')
					)
				},
				testChunks: false,
				fileType: ['mp4', 'mov'],
				fileParameterName: 'video',
				headers: {
					Authorization: `Bearer ${window.localStorage.auth_token_default}`,
					Accept: 'application/json'
				}
			})

			this.uploader.on('fileAdded', async (file) => {
				const videoDuration = await this.checkVideoDuration(file)
				if (videoDuration > 30) {
					useCreatePostStore().showValidationError(
						this.$i18n.global.t('error.videoMaxLength', { length: 30 }),
						this.$i18n.global.t('error.changeLength')
					)
					return
				}

				if (videoDuration < 3) {
					useCreatePostStore().showValidationError(
						this.$i18n.global.t('error.videoMinLength', { length: 3 }),
						this.$i18n.global.t('error.makeLongVideo')
					)
					return
				}

				this.files.push({
					file,
					status: 'uploading',
					progress: 0
				})
				this.uploader.upload()
			})
			this.uploader.on('fileSuccess', (file) => {
				const { chunks } = file
				const lastChunk = chunks[chunks.length - 1]
				const lastChunkResponse = JSON.parse(lastChunk.xhr.response)
				const { video_id, previews } = lastChunkResponse
				const createPostStore = useCreatePostStore()
				createPostStore.resetVideo()
				createPostStore.setVideo(video_id, previews)
			})
			this.uploader.on('fileProgress', (file) => {
				this.progress = Math.trunc(file.progress().toFixed(2) * 100)
			})
			this.uploader.on('uploadStart', () => {
				const createPostStore = useCreatePostStore()
				useModalStore().closeEditVideoModal()
				createPostStore.openCreatePostDialog()
				this.isVideoUploading = true
			})
			this.uploader.on('complete', () => {
				this.isVideoUploading = false
				this.isVideoUploaded = true
			})
		},
		addFile(file) {
			this.uploader.addFile(file)
		},
		resetVideo() {
			this.files = []
			this.progress = 0
		},
		async deleteVideo(videoId) {
			await createPostApi.deleteVideo(videoId)
			this.resetVideo()
		},
		cancelUpload() {
			this.uploader.cancel()
			this.resetVideo()
		},
		checkVideoDuration(file) {
			return new Promise((resolve) => {
				window.URL = window.URL || window.webkitURL
				const video = document.createElement('video')
				video.preload = 'metadata'

				video.onloadedmetadata = () => {
					window.URL.revokeObjectURL(video.src)
					resolve(Math.trunc(video.duration))
				}
				video.src = URL.createObjectURL(file.file)
			})
		}
	}
})
