<template>
	<svg-icon name="bank-card" size="13px" class="bank-card-icon" />
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'

export default {
	name: 'BadgeBankCard',
	components: { SvgIcon }
}
</script>

<style scoped lang="scss">
.bank-card-icon {
	color: #454560;
}
</style>
