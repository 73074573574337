<template>
	<component
		:to="to"
		:is="tag"
		:for="forEl"
		:href="href"
		:class="classList"
		:disabled="disabled || loading"
		@click="clickHandler($event)"
	>
		<loader-component v-if="loading" size="18px" />
		<slot v-else />
	</component>
</template>

<script>
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
	name: 'ButtonComponent',
	props: {
		tag: {
			type: String,
			default: () => 'button'
		},
		color: {
			type: String,
			default: () => 'primary'
		},
		className: {
			type: String,
			default: () => ''
		},
		shrink: {
			type: Boolean,
			default: () => false
		},
		small: {
			type: Boolean,
			default: () => false
		},
		type: {
			type: String,
			default: () => 'button'
		},
		disabled: {
			type: Boolean,
			default: () => false
		},
		loading: {
			type: Boolean,
			default: () => false
		},
		to: {
			type: [String, Object],
			default: ''
		},
		forEl: {
			type: String,
			default: ''
		},
		href: {
			type: String,
			default: ''
		}
	},
	components: {
		LoaderComponent
	},
	computed: {
		classList() {
			return [['btn', `btn-${this.color}`, this.className]]
		}
	},
	methods: {
		clickHandler(e) {
			if (this.disabled || this.loading) {
				e.preventDefault()
			}
		}
	},
	mounted() {
		if (this.shrink) {
			this.classList.push('btn-shrink')
		}
		if (this.small) {
			this.classList.push('btn-small')
		}
	}
}
</script>

<style lang="scss">
.btn {
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 10px;
	gap: 10px;
	height: 52px;
	border-radius: 12px;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	cursor: pointer;
	border: none;
	font-family: inherit;
	color: $color-white;

	@media (min-width: $screen-desktop) {
		height: 44px;
		padding-left: 18px;
		padding-right: 18px;
		transition: $transition-color, $transition-background, $transition-border;
	}
}

.btn-white {
	background: $color-white;
	color: $color-black;
	&:hover {
		color: $color-black-hover;
	}
	&:active {
		background-color: $color-white-active;
		color: $color-black;
	}
}

.btn-transparent {
	border: 1px solid $color-black-third;
	background: transparent;
	&:hover {
		border-color: $color-black-third-hover;
	}
	&:active {
		color: $color-white-active;
		border-color: $color-black-third-active;
	}
}
.btn-blank {
	color: $color-black-third;
	border: 1px solid transparent;
	background: transparent;
}

.btn-primary-outline {
	color: $color-magenta-secondary;
	border: 1px solid $color-magenta-secondary;
	background: transparent;
	&:hover {
		color: $color-magenta-secondary-hover;
	}
	&:active {
		color: $color-magenta-secondary-active;
	}
}

.btn-secondary-outline {
	color: $color-white;
	border: 1px solid $color-semi-transparent;
	background: transparent;
}

.btn-transparent:disabled {
	color: $color-gray-disabled;
}

.btn-primary {
	background: $color-magenta;
	&:hover {
		background-color: $color-magenta-hover;
	}
	&:active {
		background-color: $color-magenta-active;
	}
}

.btn-blue {
	background: linear-gradient(299.84deg, rgba(14, 63, 189, 0.5) 0%, rgba(75, 120, 235, 0.5) 97.47%);
}

.btn-primary:disabled {
	background: transparent;
	border: 1px solid $color-gray-disabled;
	box-sizing: border-box;
	color: $color-gray-disabled;
}

.btn-secondary {
	background: $color-black-secondary;
}

.btn-yellow {
	background: $color-yellow;
	color: $color-black-fourth;
}

.btn-shrink {
	width: auto;
}

.btn-small {
	font-weight: 500;
	padding: 8px 20px;
	height: 40px;
}

.btn-disabled {
	background-color: rgba($color-white, 0.3);
	color: rgba($color-white, 0.3);
	cursor: default;
}
</style>
