<template>
	<div class="post-photo placeholder-bg flex" v-if="photos">
		<img
			alt=""
			src="#"
			v-lazy="photos[0].url || photos[0]?.blurred_image?.url || photos[0]?.blurred_thumbnail"
			:height="photos[0].height"
			:width="photos[0].width"
			@load="$emit('load')"
		/>
	</div>
</template>

<script>
export default {
	name: 'PostMediaImage',
	props: {
		photos: { type: Array, default: () => [] }
	}
}
</script>
